import React, { useState } from 'react';
import { Row, Col, Flex, Button, Typography, Form } from 'antd';

import {
  UserOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import responseHandleSrv from '../../services/responseHandleSrv';
import { axios_json } from '../../axios';
import Api from '../../constants/api';
import { authHeader, authBasicHeader } from '../../services/auth-header';
import { Input, Tooltip, Checkbox, Modal } from 'antd';
import { toHaveFormValues } from '@testing-library/jest-dom/matchers';
import smsSrv from '../../services/smsSrv';
export default function Forgotpassword() {
  const { Title, Text } = Typography;
  const [open, setOpen] = useState(false);
  const [chkPhone, setChkPhone] = useState(false);
  const [form] = Form.useForm();
  const root = window.location.origin;

  const onFinish = (values) => {
    console.log('values', values);
    sendOtp(values.mobile_no);
  };
  const getPhone = (e) => {
    if (e.target.value) {
      if (e.target.value.length == 10) {
        console.log('getPhone', e.target.value.length);
        getPhoneCheck(e.target.value);
      }
    }
  };
  const sendOtp = async (mobile_no) => {
    const data = { mobile_no };

    try {
      // Make the POST request to create an OTP
      const response = await axios_json.post(`${Api.OTP_CREATE}`, data, {
        headers: authBasicHeader(),
      });

      if (response.data.success) {
        const otpData = response.data.data;
        console.log('OTP Response:', otpData);

        responseHandleSrv.handleMsg('ส่ง OTP สำเร็จ');

        // Call the sendSms function with necessary details
        await sendSms(mobile_no, otpData.ref_code, otpData.verify_code);

        // If needed, uncomment to navigate or perform additional actions
        window.location.href = `${root}/register/verify?mobileNo=${mobile_no}&ref=${otpData.ref_code}`;
      } else {
        console.error(
          'Failed to send OTP:',
          response.data.message || 'Unknown error'
        );
      }
    } catch (err) {
      console.error('Error sending OTP:', err);
      responseHandleSrv.handleError(err);
    }
  };

  const sendSms = async (mobileNo, refCode, verifyCode) => {
    const data = { mobileNo, refCode, verifyCode };

    try {
      // Send SMS using the smsSrv service
      const response = await smsSrv.sendSms(data);
      console.log('SMS sent successfully:', response.data);
    } catch (err) {
      console.error('Error sending SMS:', err);
      responseHandleSrv.handleError(err);
    }
  };

  const getPhoneCheck = (phone) => {
    console.log('getPhoneCheck', phone);

    (async () => {
      await axios_json
        .get(`${Api.CUSTOMER_CHECK_BY_MOBILE + phone}`, {
          headers: authBasicHeader(),
        })
        .then((response) => {
          console.log('response', response);
          if (response.data.success) {
            if (response.data.data) {
              setChkPhone(true);
            } else {
              responseHandleSrv.handleErrorMsg(
                'เกิดข้อผิดพลาด',
                'เบอร์โทรศัพท์นี้ไม่มีในระบบ'
              );
              setChkPhone(false);
            }
            console.log(response.data.data);
          }
        })
        .catch((err) => {
          setChkPhone(true);
          responseHandleSrv.handleError(err);
        });
    })();
  };
  const onFinishFailed = () => {};
  return (
    <>
      <Row justify='center' gutter={[0, 10]}>
        <Col span={12} offset={1} pull={1} style={{ textAlign: 'center' }}>
          <Title level={5}>ลืมรหัสผ่าน</Title>
          <p>
            <Text type='secordary'>
              รีเซ็ตรหัสผ่านด้วยเบอร์โทรศัพท์ที่ใช้งาน
            </Text>
          </p>
        </Col>
      </Row>
      <Form
        form={form}
        name='user'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row justify='center' gutter={[0, 10]}>
          <Col span={22}>
            <div>
              {/* <Title level={5} className="title-h5">
                เบอร์โทรศัพท์
              </Title>
              <Input
                placeholder="เบอร์โทรศัพท์"
                prefix={<UserOutlined className="site-form-item-icon" />}
              /> */}
              <Form.Item
                name='mobile_no'
                label={
                  <label style={{ fontWeight: 'bold', fontSize: '14px' }}>
                    เบอร์โทรศัพท์
                  </label>
                }
                // onChange={inputChange("mobile_no")}
                rules={[
                  {
                    required: true,
                    message: 'เบอร์โทรศัพท์',
                  },
                  {
                    pattern: /^[0][0-9]{9}$\b/,
                    message: 'เบอร์โทรศัพท์ไม่ถูกต้อง',
                  },
                ]}
              >
                <Input
                  onChange={getPhone}
                  placeholder='เบอร์โทรศัพท์'
                  maxLength={10}
                />
              </Form.Item>
            </div>
          </Col>

          <Col span={22} style={{ textAlign: 'center' }}>
            {/* <Button type="primary" block>
            ยืนยัน
          </Button> */}
            <Button type='primary' htmlType='submit' block disabled={!chkPhone}>
              ยืนยัน
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}
