import React from 'react';
import {axios_json} from "../axios";
import Api from "../constants/api";
import { authHeader, authBasicHeader } from "./auth-header";
import moment from "moment";



const getWithActivePeriod = () => {

    return axios_json.get(`${Api.PROMOTION_WITH_ACTIVE_PERIOD}`, { headers: authBasicHeader() });
};

const getTypeActivePeriod = (type) => {

    return axios_json.get(`${Api.PROMOTION_BY_TYPE + type}`, { headers: authBasicHeader() });
};


// eslint-disable-next-line import/no-anonymous-default-export
export default  {
   getWithActivePeriod,
   getTypeActivePeriod
};