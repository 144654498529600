import React, { useState, useEffect, useContext } from 'react';
import {
  Layout,
  Flex,
  Image,
  Row,
  Col,
  ConfigProvider,
  FloatButton,
  Typography,
  Card,
  Tag,
  Input,
} from 'antd';
import { Outlet, Navigate, NavLink, Link } from 'react-router-dom';
import placeholder from '../assets/images/placeholder_200x100.png';
import {
  UserOutlined,
  GiftOutlined,
  HomeOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import ico_profile from '../assets/images/ico-profile.svg';
import ico_promo from '../assets/images/ico-promo.svg';
import ico_tc from '../assets/images/ico-tc.svg';
import ico_history from '../assets/images/ico-history.svg';
import ico_gift from '../assets/images/ico-gift.svg';
import ico_coin from '../assets/images/ico-coin.svg';
import ico_history_2 from '../assets/images/ico-history-2.svg';
import ico_skill from '../assets/images/skill.svg';
import ico_promo2 from '../assets/images/promo2.svg';
import { HeaderContext } from '../context/headerContex';
import { formatNumberZero } from '../services/function';

const navStyle = {
  width: '20%',
  padding: '10px 0',
};

export default function Main() {
  const { Header, Content, Footer } = Layout;
  const { headerMenu } = useContext(HeaderContext);
  const { Text, Title } = Typography;
  const [line1, setLine1] = useState('');
  const [line2, setLine2] = useState('');
  const [iconProfile, setIconProfile] = useState('');
  const [icon, setIcon] = useState('');
  const [showHeader, setShowHeader] = useState(false);
  let customerData =
    sessionStorage.getItem('customer_profile') === null
      ? ''
      : JSON.parse(sessionStorage.customer_profile);
  let line_profile =
    sessionStorage.getItem('line_profile') === null
      ? ''
      : JSON.parse(sessionStorage.line_profile);
  useEffect(() => {
    // console.log("headerMenu", headerMenu);
    switch (headerMenu) {
      case 'history':
        setLine1('ประวัติ');
        setLine2('HISTORY');
        setIcon(ico_history_2);
        setIconProfile('');
        setShowHeader(true);

        break;
      case 'reward':
        setLine1('แลกรับของรางวัล');
        setLine2('REDEMTION');
        setIcon(ico_gift);
        setIconProfile('');
        setShowHeader(true);
        break;
      case 'new_arrivals':
        setLine1('สินค้าใหม่');
        setLine2('New Arrivals');
        setIcon(ico_gift);
        setIconProfile('');
        setShowHeader(true);

        break;
      case 'joyskill':
        setLine1('JOY SKILL');
        setLine2('');
        setIcon(ico_skill);
        setIconProfile('');
        setShowHeader(true);

        break;
      case 'promotion':
        setLine1('โปรโมชั่น');
        setLine2('PROMOTION');
        setIcon(ico_promo2);
        setIconProfile('');
        setShowHeader(true);

        break;

      case 'profile':
        setLine1(`คุณ ${customerData.firstname} ${customerData.lastname}`);
        setLine2(customerData.mobile_no);
        setIcon('');
        if (line_profile) {
          setIconProfile(line_profile.pictureUrl);
        } else {
          setIconProfile(customerData.picture_url);
        }

        setShowHeader(true);
        break;
      default:
        setLine1('');
        setLine2('');
        setIcon('');
        setIconProfile('');
        setShowHeader(false);
        break;
      // default:
      //   setLine1('แลกรับของรางวัล');
      //   setLine2('REDEMTION');
      //   setIcon(ico_gift);
      //   setIconProfile('');
      //   setShowHeader(false);
      //   break;
    }
  }, [headerMenu]);
  const headerStyle = {
    minHeight: '5vh',
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: '"Poppins", "Mitr"',
          fontSize: 12,
          colorTextBase: '#000000D9',
          colorPrimary: '#7745FF',
          colorLink: '#7745FF',
          colorLinkActive: '#00B19C',
        },
        components: {
          Button: {
            colorPrimary: '#7745FF',
            defaultBg: '#7745FF',
            defaultColor: '#FFF',
          },
          Tabs: {
            inkBarColor: '#752CFF',
            itemActiveColor: '#752CFF',
            itemSelectedColor: '#752CFF',
          },
        },
      }}
    >
      <Flex gap='middle' wrap='middle'>
        <Layout>
          <Header
            className='header'
            // style={{ minHeight: showHeader ? "25vh" : "5vh" }}
          >
            {showHeader && (
              <Flex
                align='center'
                justify='center'
                vertical
                style={{ height: '160px' }}
              >
                <Card
                  style={{
                    backgroundColor: '#752CFF',
                    minWidth: '101%',
                    height: '100%',
                    borderTopRightRadius: '0px',
                    borderTopLeftRadius: '0px',
                    borderBottomRightRadius: '25px',
                    borderBottomLeftRadius: '25px',
                  }}
                >
                  <Row gutter={[5, 5]} justify='center' align={'middle'}>
                    {headerMenu !== 'profile' && (
                      <Col span={8} style={{ textAlign: 'right' }}>
                        <Image
                          src={icon}
                          preview={false}
                          style={{
                            width: '110%',
                            paddingRight: '10px',
                          }}
                        />
                      </Col>
                    )}
                    <Col span={14} style={{ textAlign: 'left' }}>
                      {headerMenu === 'profile' && (
                        <>
                          <Title
                            level={3}
                            style={{ color: '#FFF', marginTop: '0px' ,width:"195px",overflow:"hidden",whiteSpace:"nowrap",textOverflow:"ellipsis"}}
                          >
                            {line1}
                          </Title>
                          <Tag
                            color='silver'
                            style={{
                              minWidth: '60%',
                              textAlign: 'center',
                              padding: '0px 5px',
                            }}
                          >
                            <Title level={4} style={{ margin: '0 auto' }}>
                              {customerData.member_class
                                ? `${customerData.member_class.toUpperCase()} MEMBER`
                                : `SILVER MEMBER`}
                            </Title>
                          </Tag>
                          <Flex
                            justify={'start'}
                            align={'center'}
                            gap={5}
                            style={{
                              backgroundColor: '#FFF',
                              padding: '5px',
                              borderRadius: '5px',
                              marginTop: '10px',
                              width: '80%',
                            }}
                          >
                            <Image
                              src={ico_coin}
                              preview={false}
                              width={30}
                              height={30}
                            />
                            <Flex vertical={true}>
                              <Title
                                level={5}
                                style={{ margin: '0px', color: '#A1A1A1' }}
                              >
                                คะแนนสะสม
                              </Title>
                              <Title
                                level={4}
                                style={{
                                  margin: '0px',
                                  position: 'relative',
                                  top: '-5px',
                                }}
                              >
                                {formatNumberZero(customerData.point)}
                              </Title>
                            </Flex>
                          </Flex>
                        </>
                      )}
                      {line1 !== '' && headerMenu !== 'profile' && (
                        <Title
                          level={2}
                          style={{
                            marginBottom: '0',
                            marginTop: '5px',
                            color: '#FFF',
                          }}
                        >
                          {line1}
                        </Title>
                      )}
                      {line2 !== '' && headerMenu !== 'profile' && (
                        <Title
                          level={2}
                          style={{ marginTop: '0', color: '#FFF' }}
                        >
                          {line2}
                        </Title>
                      )}
                    </Col>
                    {headerMenu === 'profile' && (
                      <Col span={8} style={{ textAlign: 'right' }}>
                        <Image
                          src={iconProfile}
                          preview={false}
                          style={{
                            width: '100%',
                            borderRadius: '5px',
                            borderStartStartRadius: '5px',
                            borderStartEndRadius: '5px',
                            borderEndStartRadius: '70px',
                            borderEndEndRadius: '70px',
                          }}
                        />
                      </Col>
                    )}
                  </Row>
                </Card>
              </Flex>
            )}

            {headerMenu == 'reward' && (
              <Flex justify={'flex-end'} align={'center'}>
                <Input
                  addonBefore={<SearchOutlined />}
                  style={{ lineHeight: '20px', paddingLeft: '20px' }}
                />
                <Tag
                  color={'#FF622E'}
                  style={{
                    position: 'relative',
                    right: '-5px',
                    borderTopLeftRadius: '10px',
                    borderBottomLeftRadius: '10px',
                  }}
                >
                  <Text type='secondary' style={{ color: '#FFF' }}>
                    คะแนนสะสม
                  </Text>
                  <Title level={4} style={{ marginTop: '0px', color: '#FFF' }}>
                    {formatNumberZero(customerData.point)}
                    <Image
                      src={ico_coin}
                      preview={false}
                      style={{
                        marginLeft: '2px',
                        top: '-5px',
                        position: 'relative',
                      }}
                      height={20}
                    />
                  </Title>
                </Tag>
              </Flex>
            )}
          </Header>
          <Content className='content'>
            <Outlet />
            <Row
              style={{
                background: '#FFF',
                height: '60px',
                position: 'fixed',
                bottom: '0px',
                width: '100%',
              }}
            >
              <Col span={24} style={{ textAlign: 'center' }}>
                <Flex justify={'space-between'} align={'middle'}>
                  <NavLink
                    to='/profile/history/index'
                    style={{ ...navStyle }}
                    className={headerMenu === 'history' ? 'navActive' : ''}
                  >
                    <Image
                      src={ico_history}
                      preview={false}
                      className={headerMenu === 'history' ? 'imgInvert' : ''}
                    />
                    <br />
                    <Text
                      type='secondary'
                      className={headerMenu === 'history' ? 'navActive' : ''}
                    >
                      ประวัติ
                    </Text>
                  </NavLink>
                  <NavLink
                    to='/product/index'
                    style={{ ...navStyle }}
                    className={headerMenu === 'promotion' ? 'navActive' : ''}
                  >
                    <Image
                      src={ico_promo}
                      preview={false}
                      className={headerMenu === 'promotion' ? 'imgInvert' : ''}
                    />
                    <br />
                    <Text
                      type='secondary'
                      className={headerMenu === 'promotion' ? 'navActive' : ''}
                    >
                      โปรโมชั่น
                    </Text>
                  </NavLink>
                  <NavLink
                    to='/product/reward/index'
                    style={{ ...navStyle }}
                    className={headerMenu === 'new_arrivals' ? 'navActive' : ''}
                  >
                    <Text
                      type='secondary'
                      className={
                        headerMenu === 'new_arrivals' ? 'navActive' : ''
                      }
                    >
                      NEW
                    </Text>
                    <br />
                    <Text
                      type='secondary'
                      className={
                        headerMenu === 'new_arrivals' ? 'navActive' : ''
                      }
                    >
                      ARRIVALS
                    </Text>
                  </NavLink>
                  <NavLink
                    to='/policy'
                    style={{ ...navStyle }}
                    className={({ isActive }) => (isActive ? 'navActive' : '')}
                  >
                    <Image
                      src={ico_tc}
                      preview={false}
                      className={headerMenu === 'policy' ? 'imgInvert' : ''}
                    />
                    <br />
                    <Text
                      type='secondary'
                      className={headerMenu === 'policy' ? 'navActive' : ''}
                    >
                      นโยบาย
                    </Text>
                  </NavLink>
                  <NavLink
                    to='/profile/index'
                    style={{ ...navStyle }}
                    className={headerMenu === 'profile' ? 'navActive' : ''}
                  >
                    <Image
                      src={ico_profile}
                      preview={false}
                      className={headerMenu === 'profile' ? 'imgInvert' : ''}
                    />
                    <br />
                    <Text
                      type='secondary'
                      className={headerMenu === 'profile' ? 'navActive' : ''}
                    >
                      โปรไฟล์
                    </Text>
                  </NavLink>
                </Flex>
              </Col>
            </Row>
            <Row
              id='footerLink'
              gutter={[5, 5]}
              justify='center'
              align={'bottom'}
              style={{
                background: '#FFF',
                height: '60px',
                position: 'fixed',
                bottom: '0px',
                width: '100%',
                display: 'none',
              }}
            >
              <Col span={4} style={{ textAlign: 'center' }}>
                {/* <NavLink to="/profile/history"> */}
                <NavLink to='/profile/history/index'>
                  <Image src={ico_history} preview={false} />
                  <br />
                  <Text type='secondary'>ประวัติ</Text>
                </NavLink>
              </Col>
              <Col span={4} style={{ textAlign: 'center' }}>
                <NavLink to='/product/index'>
                  <Image src={ico_promo} preview={false} />
                  <br />
                  <Text type='secondary'>โปรโมชั่น</Text>
                </NavLink>
              </Col>
              <Col span={4} style={{ textAlign: 'center' }}>
                <NavLink
                  to='/product/reward/index'
                  style={(state) => {
                    console.log('new ', state.isActive);
                  }}
                >
                  <Text type='secondary'>NEW</Text>
                  <br />
                  <Text type='secondary'>ARRIVALS</Text>
                </NavLink>
              </Col>
              <Col span={4} style={{ textAlign: 'center' }}>
                <NavLink
                  to='/policy'
                  style={(state) => {
                    console.log('policy ', state.isActive);
                  }}
                >
                  <Image src={ico_tc} preview={false} />
                  <br />
                  <Text type='secondary'>นโยบาย</Text>
                </NavLink>
              </Col>
              <Col span={4} style={{ textAlign: 'center' }}>
                <NavLink
                  to='/profile/index'
                  style={(state) => {
                    console.log('profile ', state.isActive);
                  }}
                >
                  <Image src={ico_profile} preview={false} />
                  <br />
                  <Text type='secondary'>โปรไฟล์</Text>
                </NavLink>
              </Col>
            </Row>
          </Content>
          {/* <Footer className="footer">
            <Row>
              <Col span={24}>copy right</Col>
            </Row>
          </Footer> */}
        </Layout>
      </Flex>
    </ConfigProvider>
  );
}
