import React, { useState, useEffect } from 'react';
import { Row, Col, Flex, Button, Typography, Form, notification } from 'antd';

import {
  UserOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from '@ant-design/icons';
import { Input, Tooltip, Checkbox, Modal } from 'antd';
// import { Link } from "react-router-dom"
// import Grid from '@mui/material/Grid';
// import Paper from '@mui/material/Paper';
import StepPage from './stepPage';
import responseHandleSrv from '../../services/responseHandleSrv';
import { axios_json } from '../../axios';
import { authHeader, authBasicHeader } from '../../services/auth-header';
import Api from '../../constants/api';
import axios from 'axios';
import smsSrv from '../../services/smsSrv';

const StepOneUser = ({
  nextStep,
  prevStep,
  values,
  inputChange,
  setChkConsentProp,
  gotoม,
  setRefProp,
  setOtpProp,
  setDataExitProp,
  setLineUid,
  setPic,
}) => {
  const { Title } = Typography;
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [chkPhone, setChkPhone] = useState(false);
  const [consent, setConsent] = useState();
  const headers = {
    Authorization: 'Basic ' + process.env.REACT_APP_BASIC_HEADER,
    accept: 'application/json',
  };
  const line_profile =
    sessionStorage.getItem('line_profile') === null
      ? ''
      : JSON.parse(sessionStorage.line_profile);
  // const [loadingMsg, setLoadingMsg] = useState('กำลังตรวจสอบข้อมูล Line...');
  // const root = window.location.origin;
  const [form] = Form.useForm();
  useEffect(() => {
    if (values.id > 0) {
      form.setFieldsValue({
        mobile_no: values.mobile_no,
      });
      setChkPhone(values.mobile_no ? true : false);
    }

    (async () => {
      const data = await axios.get(
        process.env.REACT_APP_API_PRD + '/api/content/consent',
        { headers }
      );
      setConsent(data.data.data.description);
    })();
  }, []);

  const onFinish = (value) => {
    console.log(value);

    console.log('values', values);
    sendOtp(values.mobile_no);
    // nextStep();
  };
  const onFinishFailed = () => {};
  const sendOtp = async (mobile_no) => {
    const data = { mobile_no };

    try {
      // Make the POST request to create an OTP
      const response = await axios_json.post(`${Api.OTP_CREATE}`, data, {
        headers: authBasicHeader(),
      });

      if (response.data.success) {
        const otpData = response.data.data;
        console.log('OTP Response:', otpData);

        responseHandleSrv.handleMsg('ส่ง OTP สำเร็จ');

        setRefProp(otpData.ref_code);
        setOtpProp(otpData.verify_code);
        await sendSms(values.mobile_no, otpData.ref_code, otpData.verify_code);
        nextStep();
      } else {
        console.error(
          'Failed to send OTP:',
          response.data.message || 'Unknown error'
        );
        let msg = response.data.message || 'Unknown error';
        responseHandleSrv.handleError(msg);
      }
    } catch (err) {
      console.error('Error sending OTP:', err);
      responseHandleSrv.handleError(err);
    }
  };

  const sendSms = async (mobileNo, refCode, verifyCode) => {
    const data = { mobileNo, refCode, verifyCode };

    try {
      // Send SMS using the smsSrv service
      const response = await smsSrv.sendSms(data);
      console.log('SMS sent successfully:', response.data);
    } catch (err) {
      console.error('Error sending SMS:', err);
      responseHandleSrv.handleError(err);
    }
  };

  const showModalError = () => {
    Modal.error({
      title: 'แจ้งเตือน',
      content: (
        <div>
          <p>
            กรุณาติดต่อเจ้าหน้าที่
            เนื่องจากเบอร์โทรศัพท์นี้เป็นของสมาชิกท่านอื่นในระบบ
          </p>
          {/* <p>กรุณาตรวจสอบข้อมูลของท่านให้ถูกต้อง</p> */}
        </div>
      ),
      onOk: () => {},
    });
  };
  const showModal = () => {
    Modal.info({
      title: 'แจ้งเตือน',
      content: (
        <div>
          <p>เบอร์โทรศัพท์นี้เป็นสมาชิกเรียบร้อยแล้ว</p>
          <p>กรุณาตรวจสอบข้อมูลของท่านให้ถูกต้อง</p>
        </div>
      ),
      onOk: () => {},
    });
  };
  const getPhone = (e) => {
    if (e.target.value) {
      if (e.target.value.length == 10) {
        // console.log('getPhone', e.target.value.length);
        getPhoneCheck(e.target.value);
      }

      // closeNoti();
    }
    // else {
    //   closeNoti();
    // }
  };
  const closeNoti = (e) => {
    notification.destroy('error');
  };
  const getPhoneCheck = (phone) => {
    console.log('getPhoneCheck', phone);

    (async () => {
      await axios_json
        .get(`${Api.CUSTOMER_PROFILE_BY_MOBILE + phone}`, {
          headers: authBasicHeader(),
        })
        .then((response) => {
          console.log('response', response);
          if (response.data.success) {
            let res = response.data.data;
            if (res) {
              setChkPhone(true);
              closeNoti();
              if (
                values.mobile_no_exist != '' &&
                values.mobile_no_exist != res.mobile_no
              ) {
                setChkPhone(false);
                //   notification.error({
                //     key : "error",
                //     message: "เกิดข้อผิดพลาด",
                //     description: "กรุณาติดต่อเจ้าหน้าที่ เนื่องจากเบอร์โทรศัพท์นี้เป็นของสมาชิกท่านอื่นในระบบ",
                //     duration : 0,
                //     onClose: closeNoti()
                // });
                showModalError();
              }
              if (values.mobile_no_exist == '') {
                // responseHandleSrv.handleInfo(
                //   "เบอร์โทรศัพท์นี้เป็นสมาชิกเรียบร้อยแล้ว กรุณาตรวจสอบข้อมูลของท่าน"
                // );
                showModal();
                setDataExitProp(res);
                if (line_profile) {
                  setLineUid(line_profile.userId);
                  setPic(line_profile.pictureUrl);
                } else {
                  setLineUid(res.line_uid ? res.line_uid : '');
                  setPic(res.picture_url ? res.picture_url : '');
                }
              }
            } else {
              setChkPhone(true);
            }
            // console.log(response.data.data);
          }
        })
        .catch((err) => {
          setChkPhone(false);
          responseHandleSrv.handleError(err);
        });
    })();
  };

  return (
    <>
      <StepPage step={0} />
      <Form
        form={form}
        name='user'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row justify='center' gutter={[0, 10]}>
          <Col span={12} offset={1} pull={1} style={{ textAlign: 'center' }}>
            <Title level={5}>สร้างบัญชี</Title>
          </Col>
        </Row>
        <Row justify='center' gutter={[0, 10]}>
          <Col span={22}>
            <div>
              <Form.Item
                name='mobile_no'
                label={
                  <label style={{ fontWeight: 'bold', fontSize: '14px' }}>
                    เบอร์โทรศัพท์
                  </label>
                }
                onChange={inputChange('mobile_no')}
                rules={[
                  {
                    required: true,
                    message: 'เบอร์โทรศัพท์',
                  },
                  {
                    pattern: /^[0][0-9]{9}$\b/,
                    message: 'เบอร์โทรศัพท์ไม่ถูกต้อง',
                  },
                ]}
              >
                <Input
                  onChange={getPhone}
                  placeholder='เบอร์โทรศัพท์'
                  maxLength={10}
                />
              </Form.Item>
            </div>
          </Col>
          <Col span={22}>
            <Form.Item
              name='is_consent'
              label=''
              valuePropName='checked'
              // onChange={inputChange('is_consent')}
              onChange={setChkConsentProp}
              rules={[
                {
                  required: true,
                  message: 'กรุณาเลือก Consent',
                },
              ]}
            >
              <Checkbox>
                <span>consent</span>
                <Button
                  type='link'
                  style={{ color: '#7745FF' }}
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  Read more
                </Button>
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={22} style={{ textAlign: 'center' }}>
            <Form.Item>
              <Button
                type='primary'
                htmlType='submit'
                block
                disabled={!values.is_consent || !chkPhone}
              >
                ยืนยัน
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Modal
        title='Consent'
        centered
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        footer={null}
        width={1000}
      >
        <div dangerouslySetInnerHTML={{ __html: consent }} />
      </Modal>
    </>
  );
};

export default StepOneUser;
