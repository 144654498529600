import React from 'react';
import { axios_json } from '../axios';
import Api from '../constants/api';
import { authHeader, authBasicHeader } from './auth-header';
import moment from 'moment';

const sendSms = (data) => {
  return axios_json.post(`${Api.SEND_SMS}`, data, {
    headers: authBasicHeader(),
  });
};

export default {
  sendSms,
};
