import React, { useEffect, useState, useContext } from 'react';
import {
  Row,
  Col,
  Space,
  Typography,
  Card,
  Avatar,
  Image,
  Flex,
  Button,
  Tag,
  Spin,
} from 'antd';
import {
  UserOutlined,
  PhoneOutlined,
  MailOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { Navigate } from 'react-router-dom';
import { Input, Tooltip, Checkbox, Modal } from 'antd';
import responseHandleSrv from '../services/responseHandleSrv';
import { axios_json } from '../axios';
import Api from '../constants/api';
import { authHeader, authBasicHeader } from '../services/auth-header';
import { formatNumberZero } from '../services/function';
import { Link } from 'react-router-dom';
import { PreloadContext } from '../context/preload';
import Preload from '../components/preload';
import Barcode from 'react-barcode';
import { HeaderContext } from '../context/headerContex';
import axios from 'axios';

export default function LandingPage() {
  //   let customer =
  //     sessionStorage.getItem("customer_profile") === null
  //       ? ""
  //       : JSON.parse(sessionStorage.customer_profile);
  //   let line_profile =
  //     sessionStorage.getItem("line_profile") === null
  //       ? ""
  //       : JSON.parse(sessionStorage.line_profile);
  const { Title, Text } = Typography;
  const [open, setOpen] = useState(false);
  const [openEstamp, setOpenEstamp] = useState(false);
  const [openJCoin, setOpenJCoin] = useState(false);

  const [data, setData] = useState('');
  const [lineProfile, setLineProfile] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState('กำลังตรวจสอบ Config...');
  const { setLoading } = useContext(PreloadContext);
  const { setHeaderMenu } = useContext(HeaderContext);
  const { Meta } = Card;
  const [estamp, setEstamp] = useState();
  const [jCoins, setJCoins] = useState();

  const root = window.location.origin;
  const headers = {
    Authorization: 'Basic ' + process.env.REACT_APP_BASIC_HEADER,
    accept: 'application/json',
  };

  useEffect(() => {
    setIsLoading(true);
    const liff = window.liff;

    getData(liff, process.env.REACT_APP_LIFFID_LANDING_PAGE);
    // getData(liff, '2002607214-2ajM6Dgp');
  }, []);
  const getData = (liff, liffId) => {
    setLoadingMsg('กำลังตรวจสอบข้อมูล Line...');
    // setLoading(true);
    main(liff, liffId).then((profile) => {
      if (profile) {
        console.log(profile);
        setLoadingMsg('กำลังตรวจสอบข้อมูลลูกค้า...');
        setLineProfile(profile);
        if (typeof Storage !== 'undefined') {
          sessionStorage.line_profile = JSON.stringify(profile);
        }
        if (profile.userId) {
          getCustomer(profile.userId)
            .then((response) => {
              console.log('getCustomer', response);
              if (response.data.success) {
                if (response.data.data) {
                  //   setData(response.data.data);
                  //   let res = response.data.data;
                  //   sessionStorage.customer_profile = JSON.stringify(res);
                  //   sessionStorage.is_customer = 1;
                  //   setIsLoading(false);
                  //   setLoading(false);
                  //   return <Navigate to='/line/profile' />;
                  window.location = `${root}/line/profile`;
                } else {
                  //   sessionStorage.is_customer = 0;
                  window.location = `${root}/line/register`;
                  //   return <Navigate to='/line/register' />;
                  //   setIsLoading(false);
                  //   setLoading(false);
                }
              } else {
              }
            })
            .catch((err) => {
              setIsLoading(false);
              setLoading(false);
              responseHandleSrv.handleError(err);
            });
        }
      }
    });
  };
  const main = async (liff, liffId) => {
    await liff.init({ liffId: `${liffId}` }).catch((err) => {
      throw err;
    });
    if (liff.isLoggedIn()) {
      return getUserProfile(liff);
    } else {
      return liff.login();
    }
  };
  const getUserProfile = async (liff) => {
    return await liff.getProfile();
  };
  const getCustomer = async (uid) => {
    return await axios_json.get(`${Api.CUSTOMER_PROFILE_BY_LINE + uid}`, {
      headers: authBasicHeader(),
    });
  };

  const getProfile = () => {
    getCustomer(lineProfile.userId)
      .then((response) => {
        if (response.data.success) {
          if (response.data.data) {
            setData(response.data.data);
            let res = response.data.data;
            sessionStorage.customer_profile = JSON.stringify(res);
            sessionStorage.is_customer = 1;
          } else {
            sessionStorage.is_customer = 0;
          }
          setHeaderMenu('profile');
          setIsLoading(false);
          setLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setLoading(false);
        responseHandleSrv.handleError(err);
      });
  };
  return (
    <>
      {isLoading && (
        <div className='loading-overlay'>
          <Spin size='large' />
          <div className='loading-content'>Loading...</div>
        </div>
      )}
    </>
  );
}
