import React, { useEffect, useState, useContext } from 'react';
import {
  Flex,
  Row,
  Col,
  Card,
  List,
  Avatar,
  Carousel,
  Typography,
  Input,
  Space,
  Select,
  Form,
  Tabs,
} from 'antd';
import { UserOutlined, RightOutlined } from '@ant-design/icons';
import BadgeMini from '../../components/badgeMini';
import product from '../../components/product';
import ProductList from '../../components/productList';
import { formatNumberZero } from '../../services/function';
import Badge from '../../components/badge';
import productSrv from '../../services/productSrv';
import responseHandleSrv from '../../services/responseHandleSrv';
import { HeaderContext } from '../../context/headerContex';
import RewardSlide from '../../components/rewardSlide';

const Reward = () => {
  const { Title } = Typography;
  const { Search } = Input;
  const { Option } = Select;
  const [preload, setPreload] = useState(false);
  const [categoriesList, setCategoriesList] = useState([]);
  const [products, setProducts] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [tabHistory, setTabHistory] = useState('all');
  const [page, setPage] = useState(1);
  const [form] = Form.useForm();
  const { setHeaderMenu } = useContext(HeaderContext);

  const [filter, setFilter] = useState({
    search: '',
    branch_id: '',
    categories: '',
  });

  const contentStyle = {
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
  };

  let data =
    sessionStorage.getItem('customer_profile') === null
      ? ''
      : JSON.parse(sessionStorage.customer_profile);
  // console.log("data", data);
  useEffect(() => {
    setHeaderMenu('new_arrivals');
    getBranchList();
    // getCategories();
  }, []);

  const getBranchList = () => {
    (async () => {
      await productSrv
        .branchList()
        .then((response) => {
          // console.log('getBranchList', response.data);
          if (response.data.success) {
            let res = response.data.data;
            setBranchList(res);
            console.log('res', res);
            setFilter((val) => ({
              ...val,
              branch_id: res[0].id,
            }));

            form.setFieldsValue({
              branch: res[0].id,
            });
          }
          setPreload(false);
        })
        .catch((err) => {
          setPreload(false);
          responseHandleSrv.handleError(err);
        });
    })();
  };
  const onSearch = (value, _e, info) => console.log(info?.source, value);
  const rewardTab = [
    {
      key: 'all',
      label: 'ทั้งหมด',
      children: (
        <ProductList
          type='all'
          tab={tabHistory}
          start={page}
          search={filter.search}
          branch_id={filter.branch_id}
        />
      ),
    },
    {
      key: 'bag',
      label: 'กระเป๋า',
      children: (
        <ProductList
          type='bag'
          tab={tabHistory}
          start={page}
          search={filter.search}
          branch_id={filter.branch_id}
        />
      ),
    },
    {
      key: 'dom',
      label: 'Dom',
      children: (
        <ProductList
          type='dom'
          tab={tabHistory}
          start={page}
          search={filter.search}
          branch_id={filter.branch_id}
        />
      ),
    },
    {
      key: 'toy',
      label: 'ของเล่น',
      children: (
        <ProductList
          type='toy'
          tab={tabHistory}
          start={page}
          search={filter.search}
          branch_id={filter.branch_id}
        />
      ),
    },
  ];
  const callback = (key) => {
    setTabHistory(key);
    setPage(1);
  };
  const OnChangeBranch = (value) => {
    setFilter((val) => ({
      ...val,
      branch_id: value,
    }));
  };
  return (
    <>
      <Flex
        justify={'space-around'}
        align={'center'}
        style={{ marginTop: '10px' }}
      >
        <RewardSlide />
      </Flex>
      <Row gutter={[10, 10]}>
        <Col xs={24} style={{ textAlign: 'center' }}>
          <Title level={4}>ของสมนาคุณที่ระลึก</Title>
        </Col>
        <Col xs={22} offset={1} style={{ textAlign: 'center' }}>
          {/* <Search placeholder="search text" allowClear onSearch={onSearch} /> */}
          <Form form={form}>
            <Form.Item name='branch'>
              <Select
                showSearch
                optionFilterProp='children'
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                // filterSort={(optionA, optionB) =>
                //   optionA.children
                //     .toLowerCase()
                //     .localeCompare(optionB.children.toLowerCase())
                // }
                placeholder='Select a option and change input text above'
                // allowClear
                onChange={OnChangeBranch}
                style={{ border: '0px solid' }}
              >
                {branchList &&
                  branchList.map((s) => (
                    <Option value={s.id} key={s.id}>
                      {s.name_th}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Form>
        </Col>

        <Col span={22} offset={1}></Col>
        {/* {data != "" && (
          <Col span={22} offset={2}>
            <Space style={{ width: "100%" }}>
              <Badge type="stamp" point={formatNumberZero(data.stamp, 0)} />
              <Badge type="point" point={formatNumberZero(data.point, 0)} />
              <Badge type="coin" point={formatNumberZero(data.coin, 0)} />
            </Space>
          </Col>
        )} */}
        <Col span={22} offset={1}>
          {/* <Tabs centered items={rewardTab} defaultActiveKey="all" /> */}
          <Tabs
            defaultActiveKey={tabHistory}
            items={rewardTab}
            centered
            size='large'
            onChange={callback}
            destroyInactiveTabPane={true}
          />
        </Col>
      </Row>
    </>
  );
};
export default Reward;
