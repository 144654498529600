import React, { useEffect, useState, useContext } from 'react';
import {
  Row,
  Col,
  Space,
  Typography,
  Card,
  Avatar,
  Image,
  Flex,
  Button,
  Tag,
} from 'antd';
import {
  UserOutlined,
  PhoneOutlined,
  MailOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { Input, Tooltip, Checkbox, Modal } from 'antd';
import Badge from '../../components/badge';
import responseHandleSrv from '../../services/responseHandleSrv';
import { axios_json } from '../../axios';
import Api from '../../constants/api';
import { authHeader, authBasicHeader } from '../../services/auth-header';
import { formatNumberZero } from '../../services/function';
import { Link } from 'react-router-dom';
import { PreloadContext } from '../../context/preload';
import Preload from '../../components/preload';
import Barcode from 'react-barcode';
import { HeaderContext } from '../../context/headerContex';
import estam from '../../assets/images/e-stam.png';
import jCoin from '../../assets/images/j-coin.png';
import game from '../../assets/images/game.png';
import newArrival from '../../assets/images/new-arrival.png';
import skill from '../../assets/images/skill.png';
import icoSettings from '../../assets/images/setting.png';
import axios from 'axios';

export default function ProfileHome() {
  let customer =
    sessionStorage.getItem('customer_profile') === null
      ? ''
      : JSON.parse(sessionStorage.customer_profile);
  let line_profile =
    sessionStorage.getItem('line_profile') === null
      ? ''
      : JSON.parse(sessionStorage.line_profile);
  const { Title, Text } = Typography;
  const [open, setOpen] = useState(false);
  const [openEstamp, setOpenEstamp] = useState(false);
  const [openJCoin, setOpenJCoin] = useState(false);

  const [data, setData] = useState(customer);
  const [lineProfile, setLineProfile] = useState(line_profile);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState('กำลังตรวจสอบ Config...');
  const { setLoading } = useContext(PreloadContext);
  const { setHeaderMenu } = useContext(HeaderContext);
  const { Meta } = Card;
  const [estamp, setEstamp] = useState();
  const [jCoins, setJCoins] = useState();

  const root = window.location.origin;
  const headers = {
    Authorization: 'Basic ' + process.env.REACT_APP_BASIC_HEADER,
    accept: 'application/json',
  };
  useEffect(() => {
    setIsLoading(true);
    // setHeaderMenu("profile");
    const liff = window.liff;
    // console.log(liff);
    if (line_profile == '') {
      getData(liff, process.env.REACT_APP_LIFFID_PROFILE);
      // getData(liff, '2002607214-7drPywXe');
    } else {
      if (customer == '') {
        getProfile();
      } else {
        setHeaderMenu('profile');
        setLoading(false);
      }
    }
    (async () => {
      const data = await axios.get(
        process.env.REACT_APP_API_PRD + '/api/content/estamp',
        { headers }
      );
      setEstamp(data.data.data.description);

      const data2 = await axios.get(
        process.env.REACT_APP_API_PRD + '/api/content/joylicoin',
        { headers }
      );
      setJCoins(data2.data.data.description);
    })();
  }, []);
  const getData = (liff, liffId) => {
    setLoadingMsg('กำลังตรวจสอบข้อมูล Line...');
    // setLoading(true);
    main(liff, liffId).then((profile) => {
      if (profile) {
        console.log(profile);
        setLoadingMsg('กำลังตรวจสอบข้อมูลลูกค้า...');
        setLineProfile(profile);
        if (typeof Storage !== 'undefined') {
          sessionStorage.line_profile = JSON.stringify(profile);
        }
        if (customer == '') {
          getCustomer(profile.userId)
            .then((response) => {
              console.log('getCustomer', response);
              if (response.data.success) {
                if (response.data.data) {
                  setData(response.data.data);
                  let res = response.data.data;
                  sessionStorage.customer_profile = JSON.stringify(res);
                  sessionStorage.is_customer = 1;
                  setIsLoading(false);
                  setLoading(false);
                } else {
                  sessionStorage.is_customer = 0;
                  setIsLoading(false);
                  setLoading(false);
                }
                setHeaderMenu('profile');
              }
            })
            .catch((err) => {
              setIsLoading(false);
              setLoading(false);
              responseHandleSrv.handleError(err);
            });
        } else {
          setHeaderMenu('profile');
          setData(customer);
          setLoading(false);
        }
      }
    });
  };
  const main = async (liff, liffId) => {
    await liff.init({ liffId: `${liffId}` }).catch((err) => {
      throw err;
    });
    if (liff.isLoggedIn()) {
      return getUserProfile(liff);
    } else {
      return liff.login();
    }
  };
  const getUserProfile = async (liff) => {
    return await liff.getProfile();
  };
  const getCustomer = async (uid) => {
    return await axios_json.get(`${Api.CUSTOMER_PROFILE_BY_LINE + uid}`, {
      headers: authBasicHeader(),
    });
  };
  const updatePicUrl = (pic_url) => {
    console.log('pic_url', pic_url);
    if (line_profile) {
      let line_pic = line_profile.pictureUrl;
      if (pic_url != line_pic) {
        updatePic(line_profile.userId, line_pic);
      }
    }
  };
  const updatePic = async (uid, url) => {
    let body = {
      pic_url: url,
    };
    return await axios_json.post(
      `${Api.CUSTOMER_UPDATE_PIC_BY_LINE + uid}`,
      body,
      {
        headers: authBasicHeader(),
      }
    );
  };

  const getProfile = () => {
    getCustomer(lineProfile.userId)
      .then((response) => {
        if (response.data.success) {
          if (response.data.data) {
            setData(response.data.data);
            let res = response.data.data;
            sessionStorage.customer_profile = JSON.stringify(res);
            sessionStorage.is_customer = 1;
            updatePicUrl(res.picture_url);
          } else {
            sessionStorage.is_customer = 0;
            updatePicUrl('');
          }
          setHeaderMenu('profile');
          setIsLoading(false);
          setLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setLoading(false);
        responseHandleSrv.handleError(err);
      });
  };

  return (
    <>
      {data.mobile_no && (
        <Card
          style={{ margin: '15px 15px 0px 15px', position: 'relative' }}
          bodyStyle={{ backgroundColor: '#E9E9E9' }}
        >
          <Row justify='center' gutter={[0, 0]}>
            <Col span={24} style={{ textAlign: 'center' }}>
              <Barcode
                value={data.mobile_no}
                height={30}
                fontSize={30}
                displayValue={false}
                background={'#E9E9E9'}
              />
            </Col>
          </Row>
          <Row justify='center' gutter={[0, 0]}>
            <Col span={24} style={{ textAlign: 'center' }}>
              <Title level={5} style={{ margin: '2px' }}>
                แสกนรหัสเพื่อให้พนักงานสะสมคะแนน
              </Title>
            </Col>
          </Row>
        </Card>
      )}
      {data && (
        <Card
          style={{ margin: '0px 15px', position: 'relative', display: 'none' }}
          bodyStyle={{ backgroundColor: '#E9E9E9' }}
          title='คะแนนใกล้หมดอายุ'
          headStyle={{
            backgroundColor: '#000',
            color: '#FFF',
            textAlign: 'center',
          }}
        >
          <Flex justify={'space-between'} align={'center'}>
            <Title level={4}>31 Dec 2024</Title>
            <span>
              <Text
                style={{
                  color: '#752CFF',
                  fontSize: '25px',
                  fontWeight: '600',
                  paddingRight: '3px',
                }}
              >
                {formatNumberZero(data.point)}
              </Text>
              <Text style={{ fontWeight: '800' }}>Point</Text>
            </span>
          </Flex>
        </Card>
      )}
      <Preload>
        <Card style={{ border: '0px', position: 'relative' }}>
          <Row>
            <Col
              span={12}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Card
                id={'profile-card-estamp'}
                style={{
                  backgroundColor: '#FBBFE2',
                  padding: '0px',
                  margin: '0px',
                  width: '100%',
                  height: '160px',
                  alignContent: 'center',
                  textAlign: 'center',
                  justifyContent: 'center',
                  justifyItems: 'center',
                }}
                bodyStyle={{ padding: '0px' }}
              >
                <Button
                  style={{
                    width: '100%',
                    height: '100%',
                    background: 'none',
                    border: 'none',
                  }}
                  onClick={() => {
                    setOpenEstamp(true);
                  }}
                >
                  <Meta
                    avatar={
                      <Image
                        src={estam}
                        style={{ width: '54px' }}
                        preview={false}
                      />
                    }
                    style={{
                      padding: '0px',
                      display: 'block',
                      paddingInlineEnd: '0px',
                    }}
                  />
                  <Meta title={<Title level={4}>E-STAMP</Title>} />
                  <Tag
                    color='#FFF'
                    style={{ minWidth: '65px', padding: '2px 8px' }}
                  >
                    <Title
                      level={4}
                      style={{
                        color: '#FBBFE2',
                        fontWeight: '800',
                        marginTop: '0px',
                        marginBottom: '0px',
                      }}
                    >
                      {formatNumberZero(data.stamp)}
                      {/* 1,000,000 */}
                    </Title>
                  </Tag>
                </Button>
              </Card>
            </Col>
            <Col
              span={12}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Card
                id={'profile-card-coin'}
                style={{
                  backgroundColor: '#D0B9E5',
                  padding: '0px',
                  margin: '0px',
                  width: '100%',
                  height: '160px',
                  alignContent: 'center',
                  textAlign: 'center',
                  justifyContent: 'center',
                  justifyItems: 'center',
                }}
                bodyStyle={{ padding: '0px' }}
              >
                <Button
                  style={{
                    width: '100%',
                    height: '100%',
                    background: 'none',
                    border: 'none',
                  }}
                  onClick={() => {
                    setOpenJCoin(true);
                  }}
                >
                  <Meta
                    avatar={
                      <Image
                        src={jCoin}
                        style={{ width: '54px' }}
                        preview={false}
                      />
                    }
                    style={{
                      padding: '0px',
                      display: 'block',
                      paddingInline: '0px',
                    }}
                  />
                  <Meta title={<Title level={4}>JOYLICOIN</Title>} />
                  <Tag
                    color='#FFF'
                    style={{ minWidth: '65px', padding: '2px 8px' }}
                  >
                    <Title
                      level={4}
                      style={{
                        color: '#7745FF',
                        fontWeight: '800',
                        marginTop: '0px',
                        marginBottom: '0px',
                      }}
                    >
                      {formatNumberZero(data.coin)}
                      {/* 10,000 */}
                    </Title>
                  </Tag>
                </Button>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col
              span={24}
              style={{
                backgroundColor: '#F9E0B0',
                borderRadius: '10px',
                padding: '0px 10px',
              }}
            >
              <Title level={3}>
                <Image src={game} style={{ paddingRight: '25px' }} />
                GAME ONLINE
              </Title>
            </Col>
          </Row>
          <Row>
            <Col
              span={24}
              style={{
                backgroundColor: '#E8B0AA',
                borderRadius: '10px',
                padding: '0px 10px',
              }}
            >
              <Title level={3}>
                <Image src={newArrival} style={{ paddingRight: '25px' }} />
                NEW ARRIVALS
              </Title>
            </Col>
          </Row>
          <Row>
            <Col
              span={24}
              style={{
                backgroundColor: '#C4DFCF',
                borderRadius: '10px',
                padding: '0px 10px',
              }}
            >
              <Link to={'/profile/skill'}>
                <Title level={3}>
                  <Image src={skill} style={{ paddingRight: '25px' }} />
                  JOY SKILL
                </Title>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col
              span={24}
              style={{
                backgroundColor: '#AACAE8',
                borderRadius: '10px',
                padding: '0px 10px',
              }}
            >
              <Link to={'/profile/setting'}>
                {' '}
                <Title level={3}>
                  <Image src={icoSettings} style={{ paddingRight: '25px' }} />
                  SETTING
                </Title>
              </Link>
            </Col>
          </Row>
        </Card>
        <Modal
          title='E-Stamp'
          centered
          open={openEstamp}
          onOk={() => setOpenEstamp(false)}
          onCancel={() => setOpenEstamp(false)}
          footer={null}
          width={1000}
        >
          <div
            style={{ minHeight: '60vh', overflow: 'scroll' }}
            dangerouslySetInnerHTML={{ __html: estamp }}
          />
        </Modal>
        <Modal
          title='JoyliCoin'
          centered
          open={openJCoin}
          onOk={() => setOpenJCoin(false)}
          onCancel={() => setOpenJCoin(false)}
          footer={null}
          width={1000}
        >
          <div
            style={{ minHeight: '60vh', overflow: 'scroll' }}
            dangerouslySetInnerHTML={{ __html: jCoins }}
          />
        </Modal>
      </Preload>
    </>
    // <>
    //   {data.mobile_no && (
    //     <Card
    //       style={{ margin: "0px 15px", position: "relative" }}
    //       bodyStyle={{ backgroundColor: "#E9E9E9" }}
    //     >
    //       <Row justify="center" gutter={[0, 0]}>
    //         <Col span={24} style={{ textAlign: "center" }}>
    //           <Barcode
    //             value={data.mobile_no}
    //             height={30}
    //             fontSize={30}
    //             displayValue={false}
    //             background={"#E9E9E9"}
    //           />
    //         </Col>
    //       </Row>
    //       <Row justify="center" gutter={[0, 0]}>
    //         <Col span={24} style={{ textAlign: "center" }}>
    //           <Title level={5} style={{ margin: "2px" }}>
    //             แสกนรหัสเพื่อให้พนักงานสะสมคะแนน
    //           </Title>
    //         </Col>
    //       </Row>
    //     </Card>
    //   )}
    //   {data && (
    //     <Card
    //       style={{ margin: "0px 15px", position: "relative" }}
    //       bodyStyle={{ backgroundColor: "#E9E9E9" }}
    //       title="คะแนนใกล้หมดอายุ"
    //       headStyle={{
    //         backgroundColor: "#000",
    //         color: "#FFF",
    //         textAlign: "center",
    //       }}
    //     >
    //       <Flex justify={"space-between"} align={"center"}>
    //         <Text>31 Dec 2024</Text>
    //         <span>
    //           <Text
    //             style={{
    //               color: "#752CFF",
    //               fontSize: "25px",
    //               fontWeight: "400",
    //               paddingRight: "3px",
    //             }}
    //           >
    //             1000
    //           </Text>
    //           <Text>Point</Text>
    //         </span>
    //       </Flex>
    //     </Card>
    //   )}
    //   <Preload>
    //     <Card style={{ border: "0px", position: "relative" }}>
    //       <Row>
    //         <Col
    //           span={12}
    //           style={{
    //             display: "flex",
    //             alignItems: "center",
    //             justifyContent: "center",
    //           }}
    //         >
    //           <Card
    //             style={{
    //               backgroundColor: "#FBBFE2",
    //               padding: "0px",
    //               margin: "0px",
    //               width: "100%",
    //               height: "140px",
    //               alignContent: "center",
    //               textAlign: "center",
    //               justifyContent: "center",
    //               justifyItems: "center",
    //               display: "flex",
    //             }}
    //           >
    //             <Meta
    //               avatar={
    //                 <Image
    //                   src={estam}
    //                   style={{ width: "54px" }}
    //                   preview={false}
    //                 />
    //               }
    //               style={{
    //                 padding: "0px",
    //                 display: "block",
    //                 paddingInlineEnd: "0px",
    //               }}
    //             />
    //             <Meta title={<Title level={4}>E-STAMP</Title>} />
    //           </Card>
    //         </Col>
    //         <Col
    //           span={12}
    //           style={{
    //             display: "flex",
    //             alignItems: "center",
    //             justifyContent: "center",
    //           }}
    //         >
    //           <Card
    //             style={{
    //               backgroundColor: "#D0B9E5",
    //               padding: "0px",
    //               margin: "0px",
    //               width: "100%",
    //               height: "140px",
    //               alignContent: "center",
    //               textAlign: "center",
    //               justifyContent: "center",
    //               justifyItems: "center",
    //               display: "flex",
    //             }}
    //           >
    //             <Meta
    //               avatar={
    //                 <Image
    //                   src={jCoin}
    //                   style={{ width: "54px" }}
    //                   preview={false}
    //                 />
    //               }
    //               style={{
    //                 padding: "0px",
    //                 display: "block",
    //                 paddingInline: "0px",
    //               }}
    //             />
    //             <Meta title={<Title level={4}>JOYLICOIN</Title>} />
    //           </Card>
    //         </Col>
    //       </Row>
    //       <Row>
    //         <Col span={24} style={{ backgroundColor: "#F9E0B0", borderRadius: "10px", padding: "0px 10px" }}>
    //           <Title level={3}><Image src={game} style={{ paddingRight: "25px" }} />GAME ONLINE</Title>
    //         </Col>
    //       </Row>
    //       <Row>
    //         <Col span={24} style={{ backgroundColor: "#E8B0AA", borderRadius: "10px", padding: "0px 10px" }}>
    //           <Title level={3}><Image src={newArrival} style={{ paddingRight: "25px" }} />NEW ARRIVALS</Title>
    //         </Col>
    //       </Row>
    //       <Row>
    //         {/* <Col span={24} style={{backgroundColor:"#C4DFCF",borderRadius:"10px",padding:"0px 10px"}}>
    //           <Title level={3}><Image src={skill} style={{paddingRight:"25px"}}/>JOY SKILL</Title>
    //         </Col> */}
    //         <Col span={24} style={{ backgroundColor: "#C4DFCF", borderRadius: "10px", padding: "0px 10px" }}>
    //           <Link to={"/profile/skill"}><Title level={3}><Image src={skill} style={{ paddingRight: "25px" }} />JOY SKILL</Title></Link>
    //         </Col>
    //       </Row>
    //       <Row>
    //         <Col span={24} style={{ backgroundColor: "#AACAE8", borderRadius: "10px", padding: "0px 10px" }}>
    //           <Link to={"/profile/setting"}>  <Title level={3}><Image src={icoSettings} style={{ paddingRight: "25px" }} />SETTING</Title></Link>
    //         </Col>
    //       </Row>

    //     </Card>
    //   </Preload>
    //   {/* <Row justify="center" gutter={[0, 10]}>
    //     <Col span={12} offset={1} pull={1} style={{ textAlign: "center" }}>
    //       <Title level={5}>โปรไฟล์</Title>
    //     </Col>
    //   </Row>
    //   <Row justify="center">
    //     <Col span={22}>
    //       <Space
    //         direction="vertical"
    //         size="middle"
    //         style={{
    //           display: "flex",
    //         }}
    //       >
    //         <Card>
    //         <Link to="/profile/detail">
    //           <Row type="flex" style={{ alignItems: "center" }}>
    //             <Col span={6}>
    //               {data.picture_url ? (
    //                 <Avatar
    //                   size={64}
    //                   icon={<UserOutlined />}
    //                   src={data.picture_url}
    //                 />
    //               ) : (
    //                 <Avatar size={64} icon={<UserOutlined />} />
    //               )}
    //             </Col>
    //             <Col span={14}>
    //               <Title level={5}>
    //                 {data.firstname} {data.lastname}
    //               </Title>
    //               {data.email && (
    //                 <p>
    //                   <MailOutlined style={{ marginRight: "5px" }} />
    //                   <Text type="secordary">{data.email}</Text>
    //                 </p>
    //               )}
    //               <p>
    //                 <PhoneOutlined
    //                   rotate={180}
    //                   style={{ marginRight: "5px" }}
    //                 />
    //                 <Text type="secordary">{data.mobile_no}</Text>
    //               </p>
    //             </Col>
    //             <Col span={4} style={{ textAlign: "right" }}>
    //               <RightOutlined
    //                 style={{
    //                   fontSize: "20px",
    //                   verticalAlign: "top",
    //                   color: "#00B19C",
    //                 }}
    //               />
    //               </Col>
    //               {data.mobile_no && (
    //                   <Col span={24} style={{textAlign:"center"}}>
    //                     <Barcode
    //                       value={data.mobile_no}
    //                       height={30}
    //                       fontSize={10}
    //                     />
    //                   </Col>
    //                 )}
    //           </Row>
    //           </Link>
    //         </Card>
    //         <Card>
    //           <Link to="/profile/history">
    //             <Row type="flex" style={{ alignItems: "center" }}>
    //               <Col span={20}>
    //                 <Title level={5}>ประวัติ</Title>
    //               </Col>
    //               <Col span={4} style={{ textAlign: "right" }}>
    //                 <RightOutlined
    //                   style={{
    //                     fontSize: "20px",
    //                     verticalAlign: "top",
    //                     color: "#00B19C",
    //                   }}
    //                 />
    //               </Col>
    //             </Row>
    //           </Link>
    //           <Row>
    //             <Col span={24}>
    //               <Space
    //                 size="small"
    //                 style={{
    //                   width: "100%",
    //                   display: "flex",
    //                   justifyContent: "center",
    //                 }}
    //               >
    //                 <Badge
    //                   type="stamp"
    //                   point={formatNumberZero(data.stamp, 0)}
    //                 />
    //                 <Badge
    //                   type="point"
    //                   point={formatNumberZero(data.point, 0)}
    //                 />
    //                 <Badge type="coin" point={formatNumberZero(data.coin, 0)} />
    //               </Space>
    //             </Col>
    //           </Row>
    //         </Card>
    //         <Card bodyStyle={{ padding: "8px 24px" }}>
    //         <Link to="/profile/setting">

    //           <Row type="flex" style={{ alignItems: "center" }}>
    //             <Col span={20}>
    //               <Title level={5}>ตั้งค่า</Title>
    //             </Col>
    //             <Col span={4} style={{ textAlign: "right" }}>
    //               <RightOutlined
    //                 style={{
    //                   fontSize: "20px",
    //                   verticalAlign: "top",
    //                   color: "#00B19C",
    //                 }}
    //               />
    //             </Col>
    //             </Row>
    //             </Link>
    //         </Card>
    //         <Card bodyStyle={{ padding: "8px 24px" }}>
    //           <Link to="/policy">
    //             <Row type="flex" style={{ alignItems: "center" }}>
    //               <Col span={20}>
    //                 <Title level={5}>นโยบาย</Title>
    //               </Col>
    //               <Col span={4} style={{ textAlign: "right" }}>
    //                 <RightOutlined
    //                   color="primary"
    //                   style={{
    //                     fontSize: "20px",
    //                     verticalAlign: "top",
    //                     color: "#00B19C",
    //                   }}
    //                 />
    //               </Col>
    //             </Row>
    //           </Link>
    //         </Card>
    //       </Space>
    //     </Col>
    //   </Row> */}
    // </>
  );
}
